import * as setChannelTypes from '../../actions/staff/setChannel/types';
import * as setTelSaleTypes from '../../actions/staff/setTelSale/types';
import * as setStaffMembershipIDTypes from '../../actions/staff/setStaffMemberID/types';

import initalState from './state';

const staffReducer = (state = initalState, action) => {
	switch (action.type) {
		case setChannelTypes.SET_ONLINE_CHANNEL: {
			return {
				...state,
				channel: action.payload.status
			};
		}

		case setTelSaleTypes.SET_TEL_SALE: {
			return {
				...state,
				telSale: action.payload.telSale
			};
		}

		case setStaffMembershipIDTypes.SET_STAFF_MEMBER_ID: {
			return {
				...state,
				staffMemberID: action.payload.staffMemberID
			};
		}

		default: {
			return state;
		}
	}
};

export default staffReducer;
