import * as TYPES from '../../../actions/account/setOutstandingBalanceDetails/types';
import initalState from './state';

const setOutstandingBalanceDetails = (state = initalState, action) => {
	switch (action.type) {
		case TYPES.SET_OUTSTANDING_BALANCE_DETAIL: {
			return {
				...state,
				[action.payload.id]: action.payload.value
			};
		}

		default: {
			return state;
		}
	}
};

export default setOutstandingBalanceDetails;
