import React, { lazy, useState, useEffect } from 'react';

import CustomRoute from './CustomRoute';

const Gyms = lazy(() => import('../../pages/gyms'));
const Gym = lazy(() => import('../../pages/gym'));
const FitnessClasses = lazy(() => import('../../pages/gym/fitness'));
const LadiesOnly = lazy(() => import('../../pages/gym/ladies'));
const Equipment = lazy(() => import('../../pages/gym/equipment'));
const SingleEquipment = lazy(() => import('../../pages/gym/equipment/single'));
const SingleClass = lazy(() => import('../../pages/gym/classes/single'));

const pageRoutes = [
	{
		path: '/find-a-gym',
		component: <Gyms />,
		active: false
	},
	{
		path: '/find-a-gym/:gymName',
		component: <Gym />,
		active: false
	},
	{
		path: '/find-a-gym/:gymName/fitnessclasses',
		component: <FitnessClasses />,
		active: false
	},
	{
		path: '/find-a-gym/:gymName/classes/:singleClass',
		component: <SingleClass />,
		active: false
	},
	{
		path: '/find-a-gym/:gymName/equipment',
		component: <Equipment />,
		active: false
	},
	{
		path: '/find-a-gym/:gymName/equipment/:equipment',
		component: <SingleEquipment />,
		active: false
	},
	{
		path: '/find-a-gym/:gymName/ladiesonly',
		component: <LadiesOnly />,
		active: false
	}
];

const FindAGymRoutes = () => {
	const [routes, setRoutes] = useState([]);

	useEffect(() => {
		setRoutes(pageRoutes.filter((el) => el.active === true));
	}, []);

	return routes.map((data, index) => (
		<CustomRoute
			key={index}
			path={data.path}
			exact
			active
			component={data.component}
		/>
	));
};

export default FindAGymRoutes;
