import { PURGE } from 'redux-persist';

import * as TYPES from '../../../actions/membership/setHomeGym/types';
import initalState from './state';

const setHomeGymReducer = (state = initalState, action) => {
	switch (action.type) {
		case TYPES.SET_HOME_GYM: {
			return {
				...state,
				homeGym: action.payload.homeGym
			};
		}

		case PURGE: {
			return initalState;
		}

		default: {
			return state;
		}
	}
};

export default setHomeGymReducer;
