import * as TYPES from '../../../actions/account/setMembershipDetails/types';
import initalState from './state';

const setMembershipDetailsReducer = (state = initalState, action) => {
	switch (action.type) {
		case TYPES.SET_MEMBERSHIP_DETAILS: {
			return {
				...state,
				homeGym: action.payload.membershipDetails.homeGym,
				homeGymCity: action.payload.membershipDetails.homeGymCity,
				plan: action.payload.membershipDetails.plan,
				payment: action.payload.membershipDetails.payment,
				nextPayment: action.payload.membershipDetails.nextPayment,
				paymentMethod: action.payload.membershipDetails.paymentMethod,
				status: action.payload.membershipDetails.status,
				wrefNumber: action.payload.membershipDetails.wrefNumber
			};
		}

		case TYPES.SET_MEMBERSHIP_DETAIL: {
			return {
				...state,
				[action.payload.id]: action.payload.detail
			};
		}

		default: {
			return state;
		}
	}
};

export default setMembershipDetailsReducer;
