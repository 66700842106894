import settings from '../../settings.json';

const getWebsiteMode = (mode = null) => {
	if (mode !== null) {
		return mode;
	}

	return settings.REACT_APP_X4L_WEBSITE_MODE;
};

export default getWebsiteMode;
