import { PURGE } from 'redux-persist';

import * as TYPES from '../../../actions/membership/setStudentVerificationError/types';
import initalState from './state';

const setStudentVerificationErrorReducer = (state = initalState, action) => {
	switch (action.type) {
		case TYPES.SET_STUDENT_VERIFICATION_ERROR: {
			return {
				...state,
				showStudentVerificationError: action.payload.show
			};
		}

		case PURGE: {
			return initalState;
		}

		default: {
			return state;
		}
	}
};

export default setStudentVerificationErrorReducer;
