import { combineReducers } from 'redux';

import fetchAllClassesReducer from './classes';

// Purchase Flow Reducers
import setHomeGym from './membership/setHomeGym';
import setPackageType from './membership/setPackageType';
import setPromo from './membership/setPromo';
import setPackage from './membership/setPackage';
import setHasComplete from './membership/setHasComplete';
import setPricing from './membership/setPricing';
import setStudentVerificationError from './membership/setStudentVerificationError';
import setUserDetails from './membership/setUserDetails';
import setStaff from './staff';
import setAddresses from './membership/setAddresses';
import setUIElementStates from './membership/setUIElementStates';

// Account Portal Reducers
import setAccountDetails from './account/setAccountDetails';
import setMembershipDetails from './account/setMembershipDetails';
import setPaymentDetails from './account/setPaymentDetails';
import setFreezeDetails from './account/setFreezeDetails';
import setOutstandingBalanaceDetails from './account/setOutstandingBalanceDetails';

// Account Management Reducers
import setCustomerDetails from './account/accountManagement/setCustomerDetails';

const rootReducer = combineReducers({
	// Class reducer stuff (Will be removed as part of later technical debt)
	classes: fetchAllClassesReducer,

	// Account portal redux reducers (None staff)
	setAccountDetails,
	setMembershipDetails,
	setPaymentDetails,
	setFreezeDetails,
	setOutstandingBalanaceDetails,

	// Account portal reducer reducers (Staff)
	setCustomerDetails,

	// Purchase flow redux reducers
	setHomeGym,
	setPackageType,
	setPromo,
	setPackage,
	setHasComplete,
	setPricing,
	setStudentVerificationError,
	setUserDetails,
	setAddresses,
	setUIElementStates,
	setStaff
});

export default rootReducer;
