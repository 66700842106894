export default {
	cancel: {
		balanceDue: 0,
		fees: 0,
		total: 0
	},
	freeze: {
		paymentPeriod: 0,
		total: 0
	},
	outstanding: {
		paymentPeriod: 0,
		total: 0,
		lateFee: 0,
		membershipFee: 0
	}
};
