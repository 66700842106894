import { PURGE } from 'redux-persist';

import * as TYPES from '../../../actions/membership/setPackage/types';
import initalState from './state';

const setPackageReducer = (state = initalState, action) => {
	switch (action.type) {
		case TYPES.SET_PACKAGE: {
			return {
				...state,
				package: action.payload.membershipPackage
			};
		}

		case PURGE: {
			return initalState;
		}

		default: {
			return state;
		}
	}
};

export default setPackageReducer;
