export const theme = {
	// Black
	lighterBlack: '#22222c',
	darkerBlack: 'black',

	// Yellow
	lighterPrimary: '#ffcb14',
	darkerPrimary: '#faa200',

	// Green
	lighterSecondary: '#21c06f',
	darkerSecondary: '#0f9662',

	// Greys
	light: '#dedede',
	darkerLight: '#c7c7ca',

	// Whites
	lightWhite: '#ffffff',
	darkWhite: '#F8F8F8',

	// Blue (REPLACE WITH ACTUAL COLORS)
	lighterBlue: '#80bfff',
	darkerBlue: '#0073e6',

	// Red (REPLACE WITH ACTUAL COLORS)
	lighterRed: '#ff8080',
	darkerRed: '#ff1a1a',
	red: '#EE4457',

	// Account Grey
	veryLight: '#f4f4f4',

	// Wizard outline

	brown: '#c7a019',

	headingFontWeight: '400',
	paragraphFontWeight: '400'
};

export default theme;
