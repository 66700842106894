import * as TYPES from '../../../../actions/account/accountManagement/setCustomerDetails/types';
import initalState from './state';

const setCustomerDetails = (state = initalState, action) => {
	switch (action.type) {
		case TYPES.SET_CUSTOMER_DETAILS: {
			return {
				...state,
				customerDetails: action.payload.customerDetails
			};
		}
		default: {
			return state;
		}
	}
};

export default setCustomerDetails;
