export default {
	personalDetails: {
		isEnteringAddressManually: false,
		hasCompletedDateOfBirth: false,
		hasSelectedAddress: false,
		isSelectAddressInputVisible: false,
		hasClickedPostcode: true,
		isDateOfBirthValid: false
	}
};
