import React, { lazy, useState, useEffect } from 'react';

import CustomRoute from './CustomRoute';

const GenericMagentoPage = lazy(() => import('../../pages/magentoPages'));

const pageRoutes = [
	{
		path: '/policies/:policy',
		component: <GenericMagentoPage />,
		active: false
	},
	{
		path: '/services/:fitnessApp',
		component: <GenericMagentoPage />,
		active: false
	},
	{
		path: '/promotions',
		component: <GenericMagentoPage />,
		active: false
	},
	{
		path: '/promotions/:promotion',
		component: <GenericMagentoPage />,
		active: false
	},
	{
		path: '/manage',
		component: <GenericMagentoPage />,
		active: false
	},
	{
		path: '/manage/:manage',
		component: <GenericMagentoPage />,
		active: false
	},
	{
		path: '/company',
		component: <GenericMagentoPage />,
		active: false
	},
	{
		path: '/company/:company',
		component: <GenericMagentoPage />,
		active: false
	},
	{
		path: '/students',
		component: <GenericMagentoPage />,
		active: false
	},
	{
		path: '/ladies',
		component: <GenericMagentoPage />,
		active: false
	},
	{
		path: '/unlimited-access-free-gym-pass',
		component: <GenericMagentoPage />,
		active: false
	},
	{
		path: '/bleeding-yellow',
		component: <GenericMagentoPage />,
		active: false
	}
];

const GenericMagentoPageRoutes = () => {
	const [routes, setRoutes] = useState([]);

	useEffect(() => {
		setRoutes(pageRoutes.filter((el) => el.active === true));
	}, []);

	return routes.map((data, index) => (
		<CustomRoute
			key={index}
			path={data.path}
			exact
			active
			component={data.component}
		/>
	));
};

export default GenericMagentoPageRoutes;
