import { PURGE } from 'redux-persist';

import * as TYPES from '../../../actions/membership/setPackageType/types';
import initalState from './state';

const setPackageTypeReducer = (state = initalState, action) => {
	switch (action.type) {
		case TYPES.SET_PACKAGE_TYPE: {
			return {
				...state,
				packageType: action.payload.packageType
			};
		}

		case PURGE: {
			return initalState;
		}

		default: {
			return state;
		}
	}
};

export default setPackageTypeReducer;
