export default {
	endDate: '',
	startDate: '',
	creditsUsed: 0,
	creditsAvaliable: 0,
	balanceDue: 0,
	paymentMethod: '',
	billingDate: '',
	freezeLength: 0
};
