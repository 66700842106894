export default {
	firstName: '',
	lastName: '',
	subscriptionID: '',
	email: '',
	mobileNumber: '',
	dateOfBirth: '',
	gender: '',
	address: {
		firstLine: '',
		secondLine: '',
		postcode: '',
		town: ''
	},
	accountActive: false,
	hasMarketingPreferences: false,
	accountType: '',
	status: '',
	errorMessage: ''
};
