import * as TYPES from '../../actions/classes/types';
import initalState from './state';

const fetchAllClassesReducer = (state = initalState, action) => {
	switch (action.type) {
		case TYPES.FETCH_CLASSES_START:
			return {
				...state,
				isFetchingClasses: true,
				fetchingClassesError: null
			};

		case TYPES.FETCH_CLASSES_SUCCESS:
			return {
				...state,
				isFetchingClasses: false,
				classesCalendar: [],
				selectedGymId: action.payload.selectedGymId,
				classes: action.payload.classes,
				hasFetched: true
			};

		case TYPES.FETCH_CLASSES_ERROR:
			return {
				...state,
				isFetchingClasses: false,
				hasFetched: false,
				selectedGymId: 0,
				classesCalendar: [],
				classes: [],
				fetchingClassesError: action.payload.error
			};
		case TYPES.RESET_CLASSES:
			return {
				...state,
				state
			};
		case TYPES.SET_CLASSES_CALENDAR_START:
			return {
				...state,
				isSettingClassesCalendar: true,
				settingClassesCalendarError: null
			};

		case TYPES.SET_CLASSES_CALENDAR_SUCCESS:
			return {
				...state,
				isSettingClassesCalendar: false,
				classesCalendar: action.payload.classesCalendar,
				hasSetClassesCalendar: true
			};

		case TYPES.SET_CLASSES_CALENDAR_ERROR:
			return {
				...state,
				isSettingClassesCalendar: false,
				classesCalendar: {},
				settingClassesCalendarError: action.payload.error
			};

		case TYPES.SET_CALENDAR_TILES_START:
			return {
				...state,
				isSettingCalendarTiles: true,
				settingCalendarTilesError: null
			};

		case TYPES.SET_CALENDAR_TILES_SUCCESS:
			return {
				...state,
				isSettingCalendarTiles: false,
				tiles: action.payload.tiles,
				hasSetCalendarTiles: true
			};

		case TYPES.SET_CALENDAR_TILES_ERROR:
			return {
				...state,
				isSettingCalendarTiles: false,
				tiles: {},
				settingCalendarTilesError: action.payload.error
			};

		case TYPES.UPDATE_CALENDAR_TILES_SELECTED_SUCCESS:
			return {
				...state,
				isSettingCalendarTiles: false,
				tiles: action.payload.tiles,
				hasSetCalendarTiles: true
			};

		default:
			return {
				...state
			};
	}
};

export default fetchAllClassesReducer;
