import React, { Suspense, useState, useEffect } from 'react';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import Theme from './components/Theme';
import GlobalStyles from './components/Normalize';

import store, { persistor } from './redux/store';

import history from './utils/navigation/history';
import settings from './settings';
import Loader from './components/Loader';

import { getAppInsights } from './utils/Telemetry/Service';
import TelemetryProvider from './utils/Telemetry/Provider';

import RoutingTable from './components/Routing';

import GoogleTagManager from './api/GoogleTagManager';

const App = () => {
	const [isConnected, setIsConnected] = useState(true);

	useEffect(() => {
		// Initialize Google Tag Manager when the user's browser is 'online' (Allows for custom events to be fired)
		if (isConnected === true) GoogleTagManager.initialize();

		// Add an online event listener
		window.addEventListener('online', () => {
			setIsConnected(true);
		});

		// Add an offline event listener
		window.addEventListener('offline', () => {
			setIsConnected(false);
		});

		// Cleanup the event listeners
		return () => {
			// Add an online event listener
			window.removeEventListener('online', () => {
				setIsConnected(true);
			});

			// Add an offline event listener
			window.removeEventListener('offline', () => {
				setIsConnected(false);
			});
		};
	}, [isConnected]);

	return (
		<>
			<ThemeProvider theme={Theme}>
				<Provider store={store}>
					<PersistGate
						loading={
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									height: '500px'
								}}
							>
								<Loader />
							</div>
						}
						persistor={persistor}
					>
						<GlobalStyles />
						<Router history={history}>
							<TelemetryProvider
								instrumentationKey={
									settings.REACT_APP_X4L_APP_INSIGHTS_INSTRUMENTATION_KEY
								}
								after={() => getAppInsights()}
							>
								{isConnected === false && (
									<div
										style={{
											padding: '1rem',
											backgroundColor: 'orange',
											zIndex: 10,
											position: 'fixed',
											top: 0,
											width: '100%',
											textAlign: 'center',
											color: '#fff'
										}}
									>
										Error not connected
									</div>
								)}

								<Suspense
									fallback={
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												height: '500px'
											}}
										>
											<Loader />
										</div>
									}
								>
									<RoutingTable />
								</Suspense>
							</TelemetryProvider>
						</Router>
					</PersistGate>
				</Provider>
			</ThemeProvider>
		</>
	);
};

export default App;
