import * as TYPES from '../../../actions/account/setPaymentDetails/types';
import initalState from './state';

const setPaymentDetailsReducer = (state = initalState, action) => {
	switch (action.type) {
		case TYPES.SET_PAYMENT_PRICING: {
			return {
				...state,
				[action.payload.id]: action.payload.prices
			};
		}

		case TYPES.RESET_PRICING: {
			return initalState;
		}

		default: {
			return state;
		}
	}
};

export default setPaymentDetailsReducer;
