export default {
	isFetchingClasses: false,
	selectedGymId: '0',
	fetchingClassesError: {},
	classes: [],
	hasFetched: false,

	isSettingClassesCalendar: false,
	settingClassesCalendarError: {},
	classesCalendar: [],
	hasSetClassesCalendar: false,

	isSettingCalendarTiles: false,
	settingCalendarTilesError: {},
	tiles: [],
	hasSetCalendarTiles: false
};
