export const FETCH_CLASSES_START = 'FETCH_CLASSES_START';

export const FETCH_CLASSES_SUCCESS = 'FETCH_CLASSES_SUCCESS';

export const FETCH_CLASSES_ERROR = 'FETCH_CLASSES_ERROR';

export const RESET_CLASSES = 'RESET_CLASSES';

export const SET_CLASSES_CALENDAR_START = 'SET_CLASSES_CALENDAR_START';

export const SET_CLASSES_CALENDAR_SUCCESS = 'SET_CLASSES_CALENDAR_SUCCESS';

export const SET_CLASSES_CALENDAR_ERROR = 'SET_CLASSES_CALENDAR_ERROR';

export const SET_CALENDAR_TILES_START = 'SET_CALENDAR_TILES_START';

export const SET_CALENDAR_TILES_SUCCESS = 'SET_CALENDAR_TILES_SUCCESS';

export const SET_CALENDAR_TILES_ERROR = 'SET_CALENDAR_TILES_ERROR';

export const UPDATE_CALENDAR_TILES_SELECTED_SUCCESS =
	'UPDATE_CALENDAR_TILES_SELECTED_SUCCESS';
