import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

export const navigate = (path, searchParams) => {
	history.push({
		pathname: path,
		search: searchParams
	});
};

export default history;
