import * as TYPES from '../../../actions/account/setAccountDetails/types';
import initalState from './state';

const setAccountDetailsReducer = (state = initalState, action) => {
	switch (action.type) {
		case TYPES.SET_ACCOUNT_DETAILS: {
			return {
				...state,
				firstName: action.payload.accountDetails.firstName,
				lastName: action.payload.accountDetails.lastName,
				subscriptionID: action.payload.accountDetails.subscriptionID,
				email: action.payload.accountDetails.email,
				mobileNumber: action.payload.accountDetails.mobileNumber,
				dateOfBirth: action.payload.accountDetails.dateOfBirth,
				gender: action.payload.accountDetails.gender,
				address: {
					firstLine: action.payload.accountDetails.firstLine,
					secondLine: action.payload.accountDetails.secondLine,
					postcode: action.payload.accountDetails.postcode,
					town: action.payload.accountDetails.town
				},
				hasMarketingPreferences:
					action.payload.accountDetails.hasMarketingPreferences,
				accountActive: action.payload.accountDetails.accountActive,
				accountType: action.payload.accountDetails.accountType,
				status: action.payload.accountDetails.status,
				errorMessage: action.payload.accountDetails.errorMessage
			};
		}

		case TYPES.SET_ACCOUNT_DETAIL: {
			return {
				...state,
				[action.payload.id]: action.payload.value
			};
		}

		default: {
			return state;
		}
	}
};

export default setAccountDetailsReducer;
