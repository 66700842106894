import { PURGE } from 'redux-persist';

import * as accountDetailsType from '../../../actions/membership/setUserDetails/createUser/types';
import * as personDetailsType from '../../../actions/membership/setUserDetails/updateUser/types';
import * as studentDetailsType from '../../../actions/membership/setUserDetails/setStudentDetails/types';

import initalState from './state';

const setUserDetailsReducer = (state = initalState, action) => {
	switch (action.type) {
		case accountDetailsType.CREATE_USER: {
			return {
				...state,
				customerAccountID: action.payload.accountDetails.customerAccountID,
				customerID: action.payload.accountDetails.customerID,
				firstName: action.payload.accountDetails.firstName,
				middleName: action.payload.accountDetails.middleName,
				lastName: action.payload.accountDetails.lastName,
				emailAddress: action.payload.accountDetails.emailAddress,
				hasTickedMarketingPreferences:
					action.payload.accountDetails.hasTickedMarketingPreferences,
				mobileNumber: action.payload.accountDetails.mobileNumber
			};
		}

		case personDetailsType.UPDATE_USER: {
			return {
				...state,
				addressLine1: action.payload.personalDetails.addressLine1,
				addressLine2: action.payload.personalDetails.addressLine2,
				city: action.payload.personalDetails.city,
				postcode: action.payload.personalDetails.postcode,
				dateOfBirth: action.payload.personalDetails.dob,
				gender: action.payload.personalDetails.gender,
				hasAgreedToTermsAndConditions:
					action.payload.personalDetails.hasAgreedToTermsAndConditions
			};
		}

		case studentDetailsType.SET_STUDENT_DETAILS: {
			return {
				...state,
				studentDetails: {
					cardNumber: action.payload.studentDetails.cardNumber,
					token: action.payload.studentDetails.token,
					email: action.payload.studentDetails.email
				}
			};
		}

		case PURGE: {
			return initalState;
		}

		default: {
			return state;
		}
	}
};

export default setUserDetailsReducer;
