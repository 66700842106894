import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';

import CustomRoute from './CustomRoute';

import GeneratePurchaseFlowRoutes from './PurchaseFlowRoutes';
import GenerateClassRoutes from './ClassRoutes';
import GenerateFindAGymRoutes from './FindAGymRoutes';
import GenerateGenericMagentoPageRoutes from './GenericMagentoPageRoutes';
import AccountPortalRoutes from './AccountPortalRoutes';
import ManagerMembershipInterimRoutes from './ManageMembershipInterimPageRoutes';

const Homepage = lazy(() => import('../../pages/homepage'));
const NoMatch = lazy(() => import('../../pages/404'));

const RoutingTable = () => (
	<>
		<Switch>
			{/* <CustomRoute path='/' exact component={<Homepage />} /> */}

			{GeneratePurchaseFlowRoutes()}

			{GenerateClassRoutes()}

			{GenerateFindAGymRoutes()}

			{AccountPortalRoutes()}

			{GenerateGenericMagentoPageRoutes()}

			{ManagerMembershipInterimRoutes()}
			<CustomRoute component={<NoMatch />} path={undefined} exact={false} />
		</Switch>
	</>
);

export default RoutingTable;
