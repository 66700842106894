import React, { useEffect, useState, lazy } from 'react';

import settings from '../../settings.json';

import CustomRoute from './CustomRoute';

const Landing = lazy(() => import('../../pages/account/Landing'));

const EmergencyContract = lazy(() =>
	import('../../pages/account/EmergencyContact')
);

const MyAccount = lazy(() => import('../../pages/account'));

const ManageMembership = lazy(() =>
	import('../../pages/account/ManageMembership')
);

const MySubscription = lazy(() => import('../../pages/account/MySubscription'));

const CancelMembership = lazy(() =>
	import('../../pages/account/MySubscription/CancelMembership')
);

const CancellationFees = lazy(() =>
	import('../../pages/account/MySubscription/CancelMembership/CancellationFees')
);

const FreezeMembership = lazy(() =>
	import('../../pages/account/MySubscription/FreezeMembership')
);

const UnfreezeMembership = lazy(() =>
	import('../../pages/account/MySubscription/UnfreezeMembership')
);

const PersonalInformation = lazy(() =>
	import('../../pages/account/PersonalInformation')
);

const EditPersonalInformation = lazy(() =>
	import('../../pages/account/PersonalInformation/Edit')
);

const MyPayments = lazy(() => import('../../pages/account/Payment/MyPayments'));

const AddCardDetails = lazy(() =>
	import('../../pages/account/Payment/AddPaymentMethod')
);

const EditCardDetails = lazy(() =>
	import('../../pages/account/Payment/EditPaymentMethod')
);

const MakePayment = lazy(() =>
	import('../../pages/account/Payment/MakeAPayment')
);

const PaymentMethods = lazy(() => import('../../pages/account/Payment'));

const UpdatePaymentDate = lazy(() =>
	import('../../pages/account/Payment/UpdatePaymentDate')
);

const PayBalance = lazy(() => import('../../pages/account/Payment/PayBalance'));

const FindACustomer = lazy(() =>
	import('../../pages/account/AccountManagement/FindACustomer')
);

const ViewACustomer = lazy(() =>
	import('../../pages/account/AccountManagement/ViewACustomer')
);

const pageRoutes = [
	{
		path: '/landing',
		component: Landing,
		active: true
	},
	{
		path: '/account/membership',
		component: ManageMembership,
		active: false
	},
	{
		path: '/account',
		component: MyAccount,
		active: false
	},
	{
		path: '/account/details',
		component: PersonalInformation,
		active: false
	},
	{
		path: '/account/details/edit',
		component: EditPersonalInformation,
		active: false
	},
	{
		path: '/account/membership',
		component: ManageMembership,
		active: false
	},
	{
		path: '/account/subscription',
		component: MySubscription,
		active: false
	},
	{
		path: '/account/payment/methods/add',
		component: AddCardDetails,
		active: false
	},
	{
		path: '/account/payment/methods/edit',
		component: EditCardDetails,
		active: false
	},
	{
		path: '/account/payment/date',
		component: UpdatePaymentDate,
		active: false
	},
	{
		path: '/account/payment/methods',
		component: PaymentMethods,
		active: false
	},
	{
		path: '/account/payment/pay-balance',
		component: PayBalance,
		active: false
	},
	{
		path: '/account/payment/:paymentType',
		component: MakePayment,
		active: false
	},
	{
		path: '/account/payment',
		component: MyPayments,
		active: false
	},
	{
		path: '/account/emergency',
		component: EmergencyContract,
		active: false
	},
	{
		path: '/account/subscription/cancel',
		component: CancelMembership,
		active: false
	},
	{
		path: '/account/subscription/freeze',
		component: FreezeMembership,
		active: false
	},
	{
		path: '/account/subscription/unfreeze',
		component: UnfreezeMembership,
		active: false
	},
	{
		path: '/account/subscription/cancellation-fees',
		component: CancellationFees,
		active: false
	},
	{
		path: '/account/management/search',
		component: FindACustomer,
		policy: 'memberservices',
		active: true
	},
	{
		path: '/account/management/user-profile',
		component: ViewACustomer,
		policy: 'memberservices',
		active: true
	}
];

const AccountPortalRoutes = () => {
	const [routes, setRoutes] = useState([]);

	useEffect(() => {
		setRoutes(pageRoutes.filter((el) => (el.active ?? true) === true));
	}, []);

	return routes.map((data, index) => (
		<CustomRoute
			key={index}
			path={data.path}
			exact
			active
			component={data.component}
			type={data.policy ?? 'customer'}
		/>
	));
};

export default AccountPortalRoutes;
