export default {
	homeGym: '',
	plan: '',
	payment: '',
	nextPayment: '',
	paymentMethod: '',
	status: '',
	homeGymCity: '',
	wrefNumber: ''
};
