import * as TYPES from '../../../actions/account/setFreezeDetails/types';
import initalState from './state';

const setFreezeDetailsReducer = (state = initalState, action) => {
	switch (action.type) {
		case TYPES.SET_FREEZE_DETAILS: {
			return {
				...state,
				endDate: action.payload.freezeDetails.freezeEndDate,
				startDate: action.payload.freezeDetails.freezeStartDate,
				creditsUsed: action.payload.freezeDetails.creditsUsed,
				creditsAvaliable: action.payload.freezeDetails.creditsAvaliable,
				balanceDue: action.payload.freezeDetails.balanceDue,
				paymentMethod: action.payload.freezeDetails.payment_method
			};
		}

		case TYPES.SET_FREEZE_DETAIL: {
			return {
				...state,
				[action.payload.id]: action.payload.freezeDetail
			};
		}

		case TYPES.RESET_FREEZE_DETAILS: {
			return initalState;
		}

		default: {
			return state;
		}
	}
};

export default setFreezeDetailsReducer;
