import React from 'react';
import propTypes from 'prop-types';
import { withTheme } from 'styled-components';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';

const LoadingIcon = ({ theme }) => (
	<div
		style={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: '50%'
		}}
	>
		<Loader
			type='Oval'
			color={theme.darkerLight}
			height={100}
			width={100}
			timeout={0}
		/>
	</div>
);

LoadingIcon.propTypes = {
	theme: propTypes.object.isRequired
};

export default withTheme(LoadingIcon);
