import { PURGE } from 'redux-persist';

import * as TYPES from '../../../actions/membership/setHasComplete/types';
import initalState from './state';

const setHasComplete = (state = initalState, action) => {
	switch (action.type) {
		case TYPES.HAS_COMPLETE_ACTION: {
			return {
				...state,
				[action.payload.id]: action.payload.value
			};
		}

		case TYPES.RESET_COMPLETE_ACTIONS: {
			return initalState;
		}

		case PURGE: {
			return initalState;
		}

		default: {
			return state;
		}
	}
};

export default setHasComplete;
