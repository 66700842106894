import React, { useEffect, useState, lazy } from 'react';

import CustomRoute from './CustomRoute';

import getWebsiteMode from '../../utils/getWebsiteMode';

const RegisterPlan = lazy(() => import('../../pages/register/Plan'));
const RegisterAccount = lazy(() => import('../../pages/register/Account'));
const RegisterPayment = lazy(() => import('../../pages/register/Payment'));
const RegisterActivate = lazy(() => import('../../pages/register/Activate'));

const Staff = lazy(() => import('../../pages/staff'));

const Redirect = lazy(() => import('./Redirect'));

/*

Routing notes:

You can't have two dynamic params next to each in the path prop e.g. /:activate/stepNumber.
React-Router can't distinguish what props ot use, so it just uses the first one it fines :D

If you want a set of dynamic routes you will need to provide a route, like this:
	path: ['/activate/daypass/:stepNumber?', '/activate/:stepNumber']

*/
const pageRoutes = [
	{
		path: '/purchase/:purchaseFlowMode?/plan/:stepNumber?',
		component:
			getWebsiteMode() === 'closed' ? (
				<Redirect url='https://social.xercise4less.co.uk/gymoffers/?utm_source=xercise4less.co.uk&utm_medium=purchase_flow&utm_campaign=register-interest&utm_content=gym-offers' />
			) : (
				<RegisterPlan />
			),
		active: false
	},
	{
		path: '/purchase/:purchaseFlowMode?/account/:stepNumber?',
		component:
			getWebsiteMode() === 'closed' ? (
				<Redirect url='https://social.xercise4less.co.uk/gymoffers/?utm_source=xercise4less.co.uk&utm_medium=purchase_flow&utm_campaign=register-interest&utm_content=gym-offers' />
			) : (
				<RegisterAccount />
			),
		active: false
	},
	{
		path: '/purchase/:purchaseFlowMode?/payment/:stepNumber?',
		component:
			getWebsiteMode() === 'closed' ? (
				<Redirect url='https://social.xercise4less.co.uk/gymoffers/?utm_source=xercise4less.co.uk&utm_medium=purchase_flow&utm_campaign=register-interest&utm_content=gym-offers' />
			) : (
				<RegisterPayment />
			),
		active: false
	},
	{
		path: ['/activate/daypass/:stepNumber?', '/activate/:stepNumber'],
		component:
			getWebsiteMode() === 'closed' ? (
				<Redirect url='https://social.xercise4less.co.uk/gymoffers/?utm_source=xercise4less.co.uk&utm_medium=purchase_flow&utm_campaign=register-interest&utm_content=gym-offers' />
			) : (
				<RegisterActivate />
			),
		active: false
	},
	{
		path: '/staff',
		component: <Staff />,
		active: false
	}
];

const PurchaseFlowRoutes = () => {
	const [routes, setRoutes] = useState([]);

	useEffect(() => {
		setRoutes(pageRoutes.filter((el) => el.active === true));
	}, []);

	return routes.map((data, index) => (
		<CustomRoute
			key={index}
			path={data.path}
			exact
			active
			component={data.component}
		/>
	));
};

export default PurchaseFlowRoutes;
