import React, { useEffect, useState, lazy } from 'react';

import CustomRoute from './CustomRoute';

const FindAClass = lazy(() => import('../../pages/classes/FindAClass'));
const GenericMagentoPage = lazy(() => import('../../pages/magentoPages'));

const pageRoutes = [
	{
		path: '/classes/find',
		component: <FindAClass />,
		active: false
	},
	{
		path: '/classes/:groupExerciseClass',
		component: <GenericMagentoPage />,
		active: false
	}
];

const ClassRoutes = () => {
	const [routes, setRoutes] = useState([]);

	useEffect(() => {
		setRoutes(pageRoutes.filter((el) => el.active === true));
	}, []);

	return routes.map((data, index) => (
		<CustomRoute
			key={index}
			path={data.path}
			exact
			active
			component={data.component}
		/>
	));
};

export default ClassRoutes;
