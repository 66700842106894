import React, { lazy, cloneElement } from 'react';
import propTypes from 'prop-types';
import { Route } from 'react-router-dom';

import { withAdalLoginApi } from '../../adalConfig';

const NoMatch = lazy(() => import('../../pages/404'));

const CustomRoute = ({ path, exact, component, type = 'default' }) => {
	switch (type) {
		/*
		Basic routing component:
		- Allows for Route to have children since were passing in a raw component e.g. <MyAccount/>

		- Route now accepts child elements

		- cloneElement(component, { ...props }) - Generates a component and adds additional props e.g. react-router render props
		
		*/
		case 'default': {
			return (
				<Route
					path={path}
					exact={exact}
					render={(props) => cloneElement(component, { ...props })}
				/>
			);
		}

		/*
		B2C Routing:

		- Requires the component to prop, withAdalLoginAPI does return a component, but when withAdalLoginAPI is passed as a route child it breaks the app routing
		Probably something to do with the

		*/
		case 'customer':
		case 'staff':
		case 'memberservices': {
			return (
				<Route
					path={path}
					exact={exact}
					component={withAdalLoginApi(component, null, (error) => (
						<NoMatch error={error} />
					))}
				/>
			);
		}

		default: {
			return (
				<Route
					path={path}
					exact={exact}
					render={(props) => cloneElement(component, { ...props })}
				/>
			);
		}
	}
};

CustomRoute.defaultProps = {
	type: 'default',
	exact: true,
	path: '/'
};

CustomRoute.propTypes = {
	path: propTypes.oneOfType([propTypes.string, propTypes.array]),
	exact: propTypes.bool,
	component: propTypes.object.isRequired,
	type: propTypes.string
};

export default CustomRoute;
