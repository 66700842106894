import React, { lazy, useState, useEffect } from 'react';

import CustomRoute from './CustomRoute';

const ManageMembershipInterimSolution = lazy(() =>
	import('../../pages/ManageMembershipInterimSolution')
);

const ManageMembershipInterimSolutionConfirmation = lazy(() =>
	import('../../pages/ManageMembershipInterimSolution/Confirmation')
);

const pageRoutes = [
	{
		path: '/membership/:type',
		component: <ManageMembershipInterimSolution />,
		active: true
	},
	{
		path: '/membership/:type/confirmation',
		component: <ManageMembershipInterimSolutionConfirmation />,
		active: true
	}
];

const ManageMembershipInterimPageRoutes = () => {
	const [routes, setRoutes] = useState([]);

	useEffect(() => {
		setRoutes(pageRoutes.filter((el) => el.active === true));
	}, []);

	return routes.map((data, index) => (
		<CustomRoute
			key={index}
			path={data.path}
			exact
			active
			component={data.component}
		/>
	));
};

export default ManageMembershipInterimPageRoutes;
