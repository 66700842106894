import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';

// Redux persistance setup
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import rootReducer from './reducers';

// Middleware setup
const middleware = [thunk];

if (process.env.NODE_ENV !== 'production') {
	middleware.push(logger);
}

// Data persistance setup
const reduxPersistConfiguration = {
	key: 'account-data',
	storage,
	stateReconciler: autoMergeLevel1,

	// References the reducer props, only the specific data
	whitelist: [
		'setAccountDetails',
		'setMembershipDetails',
		'setPaymentDetails',
		'setFreezeDetails',
		'setHomeGym',
		'setPackages',
		'setPackageType',
		'setPromo',
		'setPackage',
		'setHasComplete',
		'setPricing',
		'setTabIndex',
		'setStudentVerificationError',
		'setUserDetails',
		'setAddresses',
		'setUIElementStates',
		'setStaff',
		'setCustomerDetails'
	]
};

const persistedReducer = persistReducer(reduxPersistConfiguration, rootReducer);

const store = createStore(
	persistedReducer,
	composeWithDevTools(applyMiddleware(...middleware))
);

export const persistor = persistStore(store);

// Only really needed for the purchase flow, this will make it super easy to flush the state when the reducers are seperated (Already done in another branch :) )
export const purge = () => persistor.purge();

export default store;
