import { isEmpty } from 'lodash';
import settings from '../../settings.json';

class GoogleTagManager {
	// Setup our constants
	noscript = `
	<iframe src="https://www.googletagmanager.com/ns.html?id=${settings.REACT_APP_X4L_GOOGLE_TAGS_KEY}"
	height="0" width="0" style="display:none;visibility:hidden"></iframe>
	`;

	script = `
	document.addEventListener(
		'DOMContentLoaded',
		((w, d, s, l, i) => {
			w[l] = w[l] || [];
			w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
			var f = d.getElementsByTagName(s)[0],
				j = d.createElement(s),
				dl = l != 'dataLayer' ? '&l=' + l : '';
			j.async = true;
			j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
			j.addEventListener('load', () => {
				var _ge = new CustomEvent('gtm_loaded', { bubbles: true });
				d.dispatchEvent(_ge);
			});
			f.parentNode.insertBefore(j, f);
		})(
			window,
			document,
			'script',
			'dataLayer',
			'${settings.REACT_APP_X4L_GOOGLE_TAGS_KEY}'
		)
	);
	`;

	hasLoaded = false;

	firstLoad = true;

	// Accurately records logs on the client
	logError = (event, error) =>
		console.log(`[GoogleTagManager ${event} - Failed], ${error}`);

	// GoogleTagManager initialization, triggered in App.js (Entry point for the app)
	initialize = () => {
		try {
			// Check google tag manager hasn't already been loaded
			if (!this.hasLoaded) {
				// Create a GTM fallback
				const noScriptElement = document.createElement('noscript');
				noScriptElement.innerHTML = this.noscript;

				// When the users browser supports JavaScript inject the GA analytics tracking code defined above
				const scriptElement = document.createElement('script');
				scriptElement.innerHTML = this.script;

				// Append the head and body with the scripts created above
				document.head.insertBefore(scriptElement, document.head.childNodes[0]);
				document.body.insertBefore(
					noScriptElement,
					document.body.childNodes[0]
				);

				// When gtm_loaded has been fired set hasLoaded to true (Prevents the event from firing again)
				window.addEventListener('gtm_loaded', () => {
					this.hasLoaded = true;
				});
			}
		} catch (err) {
			this.logError('Initialization', err);
			return true;
		}
	};

	// Trigger a pageChanged event, this event is fired every time the HEAD component detects a client change
	pageChanged = (pageTitle, location) => {
		if (this.firstLoad === false) {
			try {
				window.dataLayer.push({
					event: 'pageChanged', // Event name
					page: {
						url: `${location.pathname}${location.search}`, // Built in GTM variable e.g. /purchase/plan/steptwo?club_id=0&gym_name=Castleford-Gym
						referrer:
							isEmpty(window.document.referrer) === true
								? ''
								: window.document.referrer, // Either empty or if there is a refer it will out the refer link
						title: pageTitle // pageTitle from the Head component e.g. Gym Memberships, Flexible Monthly Gym Packages at X4L Gyms
					}
				});
			} catch (err) {
				this.logError('pageChanged', err);
				return true;
			}
		} else {
			this.firstLoad = false;
		}
	};

	// Triggered onSubmit of the Harlands and Adyen form, this action is coupled with the navigateToConfirmationPage
	paymentComplete = (memberDetails, paymentDetails) => {
		try {
			window.dataLayer.push({
				event: 'paymentComplete', // Event name
				payment: {
					memberDetails, // The entire user details object from redux e.g. {firstname: 'Alex', lastname: 'Machin'}
					paymentDetails // e.g. { type: 'card/dd', amount: 9.99, success: true/false, failureReason: '' }
				},
				failureReason: '' // Must be blank to reset the reason if the event previously failed.
			});
		} catch (err) {
			this.logError('paymentComplete', err);
			return true;
		}
	};

	// Triggered when either the Harlands or Adyen endpoints failed, it records what the business lost for this customer
	paymentFailed = (memberDetails, paymentDetails) => {
		try {
			window.dataLayer.push({
				event: 'paymentFailed', // Event name
				payment: {
					memberDetails, // e.g. {firstname: 'Alex', lastname: 'Machin'}
					paymentDetails // e.g. { type: 'card/dd', amount: 9.99, success: true/false, failureReason: 'Invalid CVC' }
				}
			});
		} catch (err) {
			this.logError('paymentFailed', err);
			return true;
		}
	};

	// Triggered when the user logs in and when the email is captured after the initial registration
	emailCaptured = (email) => {
		try {
			window.dataLayer.push({
				event: 'emailCaptured', // Event name
				email // e.g. alexmachin1997@gmail.com
			});
		} catch (err) {
			this.logError('emailCaptured', err);
			return true;
		}
	};

	/*
	products: [{
		brand: gym name,
		category: package type (product type),
		name: package name (access type),
		id: package product code (product code),
		variant: standard / multigym,
		price: recurring retail price,
		metric1: recurring offer price,
		metric2: pay today offer price,
		metric3: admin offer price,
		metric4: minimum term,
		dimension1: term length (D/M/Q/Y),

	}]
	*/
	checkout = (step, option, products) => {
		// If we get passed no products, simply set the option instead
		if (products === null || products.length === 0) {
			this.checkoutOption(step, option);
		}

		try {
			window.dataLayer.push({
				event: 'checkout',
				ecommerce: {
					checkout: {
						actionField: { step, option },
						products:
							products === null || products.length === 0
								? window.dataLayer.ecommerce.checkout.products
								: products
					}
				}
			});
		} catch (err) {
			this.logError('checkout', err);
			return true;
		}
	};

	checkoutOption = (step, option) => {
		try {
			window.dataLayer.push({
				event: 'checkout_option',
				ecommerce: {
					checkout_option: {
						actionField: { step, option }
					}
				}
			});
		} catch (err) {
			this.logError('checkoutOption', err);
			return true;
		}
	};

	purchase = (product, customerId, promocode) => {
		try {
			window.dataLayer.push({
				event: 'purchase',
				ecommerce: {
					currencyCode: 'GBP',
					purchase: {
						actionField: {
							id: customerId,
							revenue: product.metric2 * 1,
							coupon: promocode
						},
						products: [product]
					}
				}
			});
		} catch (err) {
			this.logError('purchase', err);
			return true;
		}
	};

	setPromoCode = (promoCode) => {
		try {
			window.dataLayer.push({
				event: 'promotionCode',
				promotionCode: promoCode
			});
		} catch (err) {
			this.logError('setPromoCode', err);
			return true;
		}
	};

	// Either a single or array of { name: value }
	setDataLayer = (options) => {
		try {
			if (Array.isArray(options)) {
				options.forEach((option) => {
					window.dataLayer.push(option);
				});
			} else {
				window.dataLayer.push(options);
			}
		} catch (err) {
			this.logError('setDataLayer', err);
			return true;
		}
	};
}

export default new GoogleTagManager();
