import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';
import settings from './settings.json';

export const adalConfig = {
	tenant: settings.REACT_APP_ADAL_CONFIG_TENANT,
	clientId: settings.REACT_APP_ADAL_CONFIG_CLIENT_ID,
	instance: settings.REACT_APP_ADAL_CONFIG_INSTANCE,
	policy: settings.REACT_APP_ADAL_CONFIG_POLICY,
	scope: 'openid',
	redirectUri: settings.REACT_APP_ADAL_CONFIG_REDIRECT_URI,
	endpoints: {
		api: settings.REACT_APP_ADAL_CONFIG_CLIENT_ID
	},
	logOutUri: `${settings.REACT_APP_ADAL_CONFIG_LOGOUT_URI}${window.location.origin}`,
	navigateToLoginRequestUrl: false
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
	adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(
	authContext,
	adalConfig.endpoints.api
);

export const getToken = () => authContext.getCachedToken(adalConfig.clientId);

export const logout = () => {
	authContext.clearCache();
	authContext.logOut();
	sessionStorage.clear();
};
export const login = () => authContext.login();

export const clearCache = () => authContext.clearCache();
