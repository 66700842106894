export default {
	customerAccountID: '',
	customerID: '',
	firstName: '',
	lastName: '',
	emailAddress: '',
	hasTickedMarketingPreferences: false,
	hasAgreedToTermsAndConditions: false,
	mobileNumber: '',
	studentDetails: {
		cardNumber: '',
		token: '',
		email: ''
	},
	addressLine1: '',
	addressLine2: '',
	city: '',
	postcode: '',
	dateOfBirth: '',
	gender: ''
};
