export default {
	// Plan hasComplete values
	hasCompletedPlan: false,
	hasCompletedHomeGym: false,
	hasCompletedPackageType: false,
	hasCompletedPackageSelection: false,
	hasCompletedStudentVerification: false,
	hasCompletedAddons: false,

	// Account values
	hasCompletedAccount: false,
	hasCompletedAccountDetails: false,
	hasCompletedPersonalDetails: false,

	// Payment values
	hasCompletedPayment: false,

	// Activate values
	hasCompletedActivate: false,
	hasCompletedParQ: false,
	hasCompletedMemberProfile: false
};
