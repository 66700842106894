import { PURGE } from 'redux-persist';

import * as TYPES from '../../../actions/membership/setUIElementStates/types';
import initalState from './state';

const setUIElementStatesReducer = (state = initalState, action) => {
	switch (action.type) {
		case TYPES.SET_PERSONAL_DETAILS_UI_ELEMENT_STATES: {
			return {
				...state,
				personalDetails: {
					...state.personalDetails,
					isEnteringAddressManually:
						action.payload.uiElementStates.isEnteringAddressManually,
					hasCompletedDateOfBirth:
						action.payload.uiElementStates.hasCompletedDateOfBirth,
					hasSelectedAddress: action.payload.uiElementStates.hasSelectedAddress,
					isSelectAddressInputVisible:
						action.payload.uiElementStates.isSelectAddressInputVisible,
					hasClickedPostcode: action.payload.uiElementStates.hasClickedPostcode
				}
			};
		}

		case TYPES.RESET_PERSONAL_DETAILS_UI_ELEMENT_STATES: {
			return {
				...initalState,
				personalDetails: initalState.personalDetails
			};
		}

		case PURGE: {
			return initalState;
		}

		default: {
			return state;
		}
	}
};

export default setUIElementStatesReducer;
