import React from 'react';
import ReactDOM from 'react-dom';
import { runWithAdal } from 'react-adal';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import { authContext } from './adalConfig';

const DO_NOT_LOGIN = true;

runWithAdal(
	authContext,
	() => {
		// eslint-disable-next-line
		require('./App');
	},
	DO_NOT_LOGIN
);

ReactDOM.render(<App />, document.getElementById('root'));

// serviceWorker.registerServiceWorker();
